import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, switchMap, tap, timeout } from 'rxjs/operators';
import { EnvironmentData } from '../types/environment-data';
import { SingleEnvironmentMeta } from '../types/single-environment-meta';

@Injectable({
	providedIn: 'root'
})
export class EnvironmentService {
	environmentData?: EnvironmentData;

	constructor(private http: HttpClient) {}

	load(): Observable<unknown> {
		const hostname = window.location.hostname;
		return this.http.get<EnvironmentData>(`/assets/environments/${hostname}.json`).pipe(
			tap(envData => this.environmentData = envData),
			switchMap(envData => {
				const observables = envData.envs.map(env => this.http.get<SingleEnvironmentMeta>(`https://${env.url}/static/foodcase-meta.json`).pipe(
					timeout(5000),
					tap(meta => env.meta = meta),
					catchError(() => of(true))
				));
				return forkJoin(observables);
			})
		);
	}
}
