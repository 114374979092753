<section>
	<div class="wrapper">
		<div class="segment">
			<div class="segment-header">
				Before first launch
			</div>
			<div class="segment-body">
				<p>If you are working form inside your company network, you might need to add exceptions to your firewall. To do that, please contact your system administrator. <br />
					The following ports need to be open:
				</p>
				<ul>
					<li *ngFor="let env of environmentService.environmentData?.envs">
						{{ env.name }} -
						<span>
							<app-if-has-meta [meta]="env.meta">
								{{ env.meta?.server }}.foodcase-services.com:{{ env.meta?.port }}
							</app-if-has-meta>
						</span>
					</li>
				</ul>
				<p>Communication method for all ports mentioned is Java RMI.</p>

				<div class="sub-segment">
					<div class="sub-segment-header">
						Java Security
					</div>
					<div class="sub-segment-body">
						<p>Java blocks by default applications which are self-signed and you get the following message:</p>
						<div class="text-center image-group-1">
							<img src="assets/first-run/1.png" alt="" />
						</div>
						<p>To avoid this launch Configure Java and open the Security tab.</p>
						<div class="text-center image-group-2">
							<img src="assets/first-run/2.png" alt="" />
							<img src="assets/first-run/3.png" alt="" />
						</div>
						<p class="mb-0">Select [Edit Site List...], then [Add] and paste the website address from where you want to launch FoodCASE. <br/>
							The following addresses are used depending on environment: </p>
						<ul>
							<li *ngFor="let env of environmentService.environmentData?.envs">
								{{ env.name }} - https://{{ env.url }}
							</li>
						</ul>
						<p class="mt-0">Select [OK] and close the Java Control Panel. FoodCASE will no longer be blocked.</p>
						<div class="text-center image-group-3">
							<img src="assets/first-run/4.png" alt="" />
						</div>
						<p>Now when launching FoodCASE you will see the following window. Just select that you accept the risk and want to run the application.</p>
						<div class="text-center image-group-4">
							<img src="assets/first-run/5.png" alt="" />
						</div>
					</div>
				</div>

				<a routerLink="/home" class="big-link"><< Back</a>
			</div>
		</div>
	</div>
</section>
