<header>
	<div class="wrapper">
		<a routerLink="home" class="logo">
			<div class="position-relative logo-inner">
				<img [src]="'assets/logos/foodcase' + (environmentService.environmentData?.lightMotive ? '-light' : '') + '.png'" alt="FoodCASE" />
				<span class="env-name">{{ environmentService.environmentData?.name }}</span>
			</div>
		</a>
		<nav>
			<ul>
				<li>
					<a routerLink="home" routerLinkActive="active">Home</a>
				</li>
				<li>
					<a routerLink="docs" routerLinkActive="active">Documentation</a>
				</li>
				<li>
					<a routerLink="support" routerLinkActive="active">Support</a>
				</li>
			</ul>
		</nav>
	</div>
</header>
