import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HomeFCSegmentComponent } from './home/home-fc-segment/home-fc-segment.component';
import { PreFirstLaunchPage } from './pre-first-launch/pre-first-launch.page';
import { SupportPage } from './support/support.page';
import { HomePage } from './home/home.page';
import { DocsPage } from './docs/docs.page';

@NgModule({
	declarations: [
		HomePage,
		HomeFCSegmentComponent,
		PreFirstLaunchPage,
		DocsPage,
		SupportPage,
	],
	imports: [
		SharedModule,
		RouterModule
	]
})
export class PagesModule {}
