import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { globalLoader } from '../global-loader';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RouterModule } from '@angular/router';
import { EnvironmentService } from './services/environment.service';

@NgModule({
	declarations: [
		HeaderComponent,
		FooterComponent
	],
	imports: [
		CommonModule,
		HttpClientModule,
		RouterModule
	],
	exports: [
		HeaderComponent,
		FooterComponent
	],
	providers: [
		{
			provide: APP_INITIALIZER,
			multi: true,
			deps: [EnvironmentService],
			useFactory: (environmentService: EnvironmentService) => () => {
				return environmentService.load().toPromise()
					.then(() => globalLoader.next({ status: 1, message: 'Application ready' }))
					.catch(() => globalLoader.next({ status: -1, message: 'Error while loading application data' }));
			}
		}
	]
})
export class CoreModule {}
