import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EnvironmentService } from '../../services/environment.service';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
	constructor(public environmentService: EnvironmentService) {}
}
