<section class="section-home">
	<div class="wrapper">
		<div class="segment">
			<div class="segment-body introduction-block">
				<h1>Welcome to FoodCASE {{ environmentService.environmentData?.name }}</h1>
				<h2>
					<ng-container *ngIf="isPremotecPage(); else notPremotecPage">
						This website was created to allow users to test the newest FoodCASE versions.<br />
						You can chose one of the two ways to launch FC (webstart or by opening the batch file from the downloaded ZIP).
						Contact <a routerLink="/support">Support</a> to receive credentials to log in.
					</ng-container>

					<ng-template #notPremotecPage>
						This website was created as a hub for a customer.<br />
						In case of any problems, please contact <a routerLink="/support">support</a>.
					</ng-template>
				</h2>
				<a routerLink="/pre-first-launch" class="big-link">Before first launch >></a>
			</div>
		</div>

		<ng-container *ngFor="let env of environmentService.environmentData?.envs">
			<ng-container>
				<app-home-fc-segment type="webstart" [environment]="env"></app-home-fc-segment>
				<app-home-fc-segment type="zip" [environment]="env"></app-home-fc-segment>
			</ng-container>
		</ng-container>
	</div>
</section>
