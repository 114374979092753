<section>
	<div class="wrapper">
		<div class="segment">
			<div class="segment-header">
				Support
			</div>
			<div class="segment-body support-block">
				<div class="support-content">
					<p>Please provide your contact information and describe the issue or topic you would like to discuss.<br /><br />
						Our Support team will transfer the information to the proper people and will get back to you as soon as possible.<br /><br />
						To speed up the process and avoid follow-up questions be precise with your request.</p>
				</div>
				<div class="support-form">
					<form class="clearfix" [formGroup]="form" (ngSubmit)="onSubmit()">
						<label>E-mail address</label>
						<input type="email" formControlName="email" />
						<label>Topic</label>
						<input type="text" formControlName="subject" />
						<label>Description</label>
						<textarea formControlName="message"></textarea>
						<p class="form-output" [ngClass]="formOutput?.class || ''">{{ formOutput?.message }}</p>
						<button class="btn">SEND</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</section>
