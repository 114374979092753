import { Component } from '@angular/core';
import { EnvironmentService } from '../../core/services/environment.service';

@Component({
	selector: 'app-pre-first-launch',
	templateUrl: './pre-first-launch.page.html',
	styleUrls: ['./pre-first-launch.page.scss']
})
export class PreFirstLaunchPage {
	constructor(public environmentService: EnvironmentService) {}
}
