import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DocsPage } from './pages/docs/docs.page';
import { HomePage } from './pages/home/home.page';
import { PreFirstLaunchPage } from './pages/pre-first-launch/pre-first-launch.page';
import { SupportPage } from './pages/support/support.page';

const routes: Routes = [
	{
		path: 'home',
		component: HomePage
	},
	{
		path: 'docs',
		component: DocsPage
	},
	{
		path: 'support',
		component: SupportPage
	},
	{
		path: 'pre-first-launch',
		component: PreFirstLaunchPage
	},
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'home'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }
