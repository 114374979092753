import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IfHasMetaComponent } from './if-has-meta/if-has-meta.component';

@NgModule({
	declarations: [IfHasMetaComponent],
	imports: [
		CommonModule,
		ReactiveFormsModule
	],
	exports: [
		CommonModule,
		ReactiveFormsModule,
		IfHasMetaComponent
	]
})
export class SharedModule { }
