import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SingleEnvironmentMeta } from '../../core/types/single-environment-meta';

@Component({
	selector: 'app-if-has-meta',
	templateUrl: './if-has-meta.component.html',
	styleUrls: ['./if-has-meta.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IfHasMetaComponent {
	@Input() meta?: SingleEnvironmentMeta;
}
