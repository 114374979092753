import { Component } from '@angular/core';
import { EnvironmentService } from '../../core/services/environment.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.page.html',
	styleUrls: ['./home.page.scss']
})
export class HomePage {
	constructor(public environmentService: EnvironmentService) {}

	isPremotecPage(): boolean {
		const envData = this.environmentService.environmentData;
		return !!envData && ['Premotec', 'Playground'].includes(envData.name);
	}
}
