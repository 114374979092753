<div class="segment">
	<div class="segment-header">
		{{ environment?.name }} - {{ type === 'webstart' ? 'Webstart' : 'ZIP Download' }}
		<div class="segment-header-note">
			<app-if-has-meta [meta]="environment.meta" *ngIf="environment">
				Version {{ environment.meta?.version }}, updated on {{ environment.meta?.updateTime | date: 'dd.MM.y' }}
			</app-if-has-meta>
		</div>
	</div>
	<div class="segment-body foodcase-block">
		<div class="foodcase-content">
			<ng-container *ngIf="type === 'webstart'">
				<ol>
					<li>Click on the icon to start download.</li>
					<li>During downloading your browser may ask you if you want to download file, you need to agree.</li>
					<li>After download, you will get a security prompt that ask if you are sure that you want to run the application. The application uses a self-signed certificate and that is why this security prompt show up. Just accept the risk and select Run.</li>
				</ol>
			</ng-container>

			<ng-container *ngIf="type === 'zip'">
				<ol>
					<li>Download FoodCASE User Client or Admin Tool (zip file).</li>
					<li>Extract zip file to the folder of your choice.</li>
					<li>Go to that folder and double click on the FoodCASE Client.bat or FoodCASE Admin.bat. If you get a message that Windows protected your PC, click on more info and click on the button “Run anyway”.</li>
				</ol>
				<p><strong>Works only on Windows OS.</strong></p>
			</ng-container>
		</div>

		<div class="foodcase-clients">
			<a [href]="getUrl('client')" class="foodcase-client">
				<img src="assets/FC-Client.png" alt="" />
				User Client
			</a>
			<a [href]="getUrl('admin')" class="foodcase-client">
				<img src="assets/FC-Admin.png" alt="" />
				Admin Tool
			</a>
		</div>
	</div>
</div>
