import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SingleEnvironmentData } from '../../../core/types/single-environment-data';

@Component({
	selector: 'app-home-fc-segment',
	templateUrl: './home-fc-segment.component.html',
	styleUrls: ['./home-fc-segment.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeFCSegmentComponent {
	@Input() environment?: SingleEnvironmentData;
	@Input() type: 'webstart' | 'zip' = 'webstart';

	getUrl(clientType: 'client' | 'admin'): string {
		if (!this.environment) {
			return '';
		}
		let url = `https://${this.environment.url}/static/${clientType}/`;

		if (this.type === 'webstart') {
			url += clientType + '.jnlp';
		} else {
			url += 'FoodCASE_' + clientType.slice(0, 1).toUpperCase() + clientType.slice(1) + '.zip';
		}

		return url;
	}
}
