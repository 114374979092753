import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
	selector: 'app-support',
	templateUrl: './support.page.html',
	styleUrls: ['./support.page.scss']
})
export class SupportPage {
	form: FormGroup = new FormGroup({
		email: new FormControl(''),
		subject: new FormControl(''),
		message: new FormControl('')
	});
	formOutput?: {
		class: string;
		message: string;
	};

	constructor(private http: HttpClient) {}

	onSubmit(): void {
		delete this.formOutput;
		this.http.post<string>('/api/mailer.php', this.form.getRawValue()).subscribe(
			message => this.formOutput = {
				class: 'success',
				message
			},
			error => this.formOutput = {
				class: 'error',
				message: (error.status === 400 && error.error) ||
					'Internal server error, please try again or use email address in footer to contact us directly'
			}
		);
	}
}
