import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EnvironmentService } from './core/services/environment.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	globalClasses = '';

	constructor(
		private environmentService: EnvironmentService,
		@Inject(DOCUMENT) private document: Document,
		private title: Title
	) {}

	ngOnInit(): void {
		const envData = this.environmentService.environmentData;
		if (envData) {
			if (envData.colors) {
				if (envData.colors.main) {
					this.document.documentElement.style.setProperty('--color-primary', envData.colors.main);
				}
				if (envData.colors.action) {
					this.document.documentElement.style.setProperty('--color-primary-action', envData.colors.action);
				}
				if (envData.colors.bg) {
					this.document.documentElement.style.setProperty('--color-header-bg', envData.colors.bg);
				}
			}

			this.title.setTitle('FoodCASE ' + envData.name);

			if (envData.lightMotive) {
				this.globalClasses = 'motive-light';
			}
		}
	}
}
