import { Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

export const globalLoader = new Subject<{ status: number, message: string }>();

const globalLoaderEl = document.querySelector('.global-loader');
const globalLoaderMsgEl = globalLoaderEl?.querySelector('.status');

globalLoader.pipe(
	takeWhile(loaderState => loaderState.status <= 0, true)
).subscribe(loaderState => {
	if (globalLoaderEl && globalLoaderMsgEl) {
		if (loaderState.status < 0) {
			globalLoaderMsgEl.classList.add('c-error');
		} else {
			globalLoaderMsgEl.classList.remove('c-error');

			if (loaderState.status > 0) {
				globalLoaderEl.classList.add('hidden');
			}
		}

		globalLoaderMsgEl.textContent = loaderState.message;
	}
});
