<footer>
	<div class="wrapper">
		<div class="contact-container">
			<ul>
				<li>
					<a href="mailto:support@foodcase.ch" class="text-link-with-icon">
						<div class="icon">
							<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
								<path d="M28 5h-24c-2.209 0-4 1.792-4 4v13c0 2.209 1.791 4 4 4h24c2.209 0 4-1.791 4-4v-13c0-2.208-1.791-4-4-4zM2 10.25l6.999 5.25-6.999 5.25v-10.5zM30 22c0 1.104-0.898 2-2 2h-24c-1.103 0-2-0.896-2-2l7.832-5.875 4.368 3.277c0.533 0.398 1.166 0.6 1.8 0.6 0.633 0 1.266-0.201 1.799-0.6l4.369-3.277 7.832 5.875zM30 20.75l-7-5.25 7-5.25v10.5zM17.199 18.602c-0.349 0.262-0.763 0.4-1.199 0.4s-0.851-0.139-1.2-0.4l-12.8-9.602c0-1.103 0.897-2 2-2h24c1.102 0 2 0.897 2 2l-12.801 9.602z"></path>
							</svg>
						</div>
						<span class="text">support@foodcase.ch</span>
					</a>
				</li>
			</ul>
		</div>
		<div class="logo-container">
			<a href="https://foodcase.ch" class="logo">
				<img [src]="'assets/logos/foodcase' + (environmentService.environmentData?.lightMotive ? '-light' : '') + '.png'" alt="FoodCASE" />
			</a>
		</div>
		<div class="logo-container">
			<a href="https://premotec.ch" class="logo">
				<img [src]="'assets/logos/premotec' + (environmentService.environmentData?.lightMotive ? '-light' : '') + '.png'" alt="Premotec" />
			</a>
		</div>
	</div>
</footer>
