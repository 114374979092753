<section>
	<div class="wrapper">
		<div class="segment">
			<div class="segment-header">
				Manuals
			</div>
			<div class="segment-body d-flex docs-1">
				<div class="sub-segment doc">
					<div class="sub-segment-header">
						Manuals on <a href="https://docs.premotec.ch">docs.premotec.ch</a>
					</div>
					<ul class="sub-segment-body doc-links">
						<li><a href="https://docs.premotec.ch/books/foodcase-user-manual/chapter/food-composition">Food Composition Manual</a></li>
						<li><a href="https://docs.premotec.ch/books/foodcase-user-manual/chapter/branded-foods">Branded Foods Manual</a></li>
						<li><a href="https://docs.premotec.ch/books/foodcase-user-manual/chapter/release-notes">Release notes</a></li>
						<li>Contact <a routerLink="/support">Support</a> to gain access to these documents</li>
					</ul>
				</div>

				<div class="sub-segment doc">
					<div class="sub-segment-header">
						Manuals financed by <a href="https://www.eurofir.org/">EuroFIR AISBL</a>
					</div>
					<ul class="sub-segment-body doc-links">
						<li><a href="assets/workshop/FoodCASE_Compiler_Manual_20130123.pdf">User Client Manual</a></li>
						<li><a href="assets/workshop/FoodCASE_Admin_Manual_20130528.pdf">Admin Tool Manual</a></li>
					</ul>
				</div>
			</div>
		</div>

		<div class="segment">
			<div class="segment-header">
				Workshop Documents
			</div>
			<div class="segment-body d-flex docs-2">
				<div class="sub-segment doc">
					<div class="sub-segment-header">
						Food Composition
					</div>
					<ul class="sub-segment-body doc-links">
						<li><a href="assets/workshop/food-composition/0 FoodCASE Training Introduction.pdf">Concepts</a></li>
						<li><a href="assets/workshop/food-composition/1 FoodCASE Training Comp Practical Part 1.pdf">Find and enter data</a></li>
						<li><a href="assets/workshop/food-composition/2 FoodCASE Training Comp Practical Part 2.pdf">Compile data</a></li>
						<li><a href="assets/workshop/food-composition/3 FoodCASE Training Comp Practical Part 3.pdf">Admin Tool</a></li>
						<li><a href="assets/workshop/food-composition/4 FoodCASE Training Comp Practical Part 4.pdf">Additional functionalities</a></li>
						<li><a href="assets/workshop/food-composition/5 FoodCASE Training Comp Practical Part 5.pdf">Tips and tricks</a></li>
					</ul>
				</div>
				<div class="sub-segment doc">
					<div class="sub-segment-header">
						Food Consumption
					</div>
					<ul class="sub-segment-body doc-links">
						<li><a href="assets/workshop/food-consumption/10 FoodCASE Training Cons Theoretical Part.pdf">Concept</a></li>
						<li><a href="assets/workshop/food-consumption/11 FoodCASE Training Cons Practical Part 1.pdf">Find and enter data</a></li>
						<li><a href="assets/workshop/food-consumption/12 FoodCASE Training Cons Practical Part 2.pdf">Admin Tool</a></li>
					</ul>
				</div>
				<div class="sub-segment doc">
					<div class="sub-segment-header">
						Total Diet Study
					</div>
					<ul class="sub-segment-body doc-links">
						<li><a href="assets/workshop/total-diet-study/FoodCASE_TDS_Part1_Introduction.pdf">Introduction</a></li>
						<li><a href="assets/workshop/total-diet-study/FoodCASE_TDS_Part2_Basic-Operations.pdf">Basic operations</a></li>
						<li><a href="assets/workshop/total-diet-study/FoodCASE_TDS_Part3_Planning-Study-and-Admin-Tasks.pdf">Planning study and admin tasks</a></li>
						<li><a href="assets/workshop/total-diet-study/FoodCASE_TDS_Part4_Data-Import.pdf">Data import</a></li>
						<li><a href="assets/workshop/total-diet-study/FoodCASE_TDS_Part5_Mobile-App.pdf">Mobile app</a></li>
						<li><a href="assets/workshop/total-diet-study/FoodCASE_TDS_Part6_MINTEL-Interface.pdf">MINTEL interface</a></li>
						<li><a href="assets/workshop/total-diet-study/FoodCASE_TDS_Part7_Barcodes-and-Labels.pdf">Barcodes and labels</a></li>
						<li><a href="assets/workshop/total-diet-study/FoodCASE_TDS_Part8_Bulk-Operations.pdf">Bulk operations</a></li>
					</ul>
				</div>
				<div class="sub-segment doc">
					<div class="sub-segment-header">
						Branded Food
					</div>
					<ul class="sub-segment-body doc-links">
						<li>Comming soon</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</section>
